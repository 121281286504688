import React from "react";
import { ChevronRight, ChevronUp, InfoCircle } from 'react-iconly';
import Form from 'react-bootstrap/Form';
import { preloader, autoClickable, success, fail, catch_errors, success1 } from "../Api/RequestFunctions";
import Checkout from "../payment/checkout";
import { getCurrency } from "../payment/GetCurrency";
import { useState } from 'react';
import { ValidateForms } from "../Auth/ValidateForms";
import axios from "axios";
import { 
    API_URL_DEPOSIT,
    API_URL_GET_INVESTMENT_OPTION, 
    TOKEN, API_URL_GET_INVESTMENT_CLASS_OPTIONS,
    API_URL_GET_FUND_INVESTMENT_CLASS,
    API_URL_VALIDATE_MM_NUMBER,
    API_URL_REQUEST_PAYMENT,
    API_URL_GET_TRANSACTION
} from "../apis";
import Conversion from "../payment/conversion";
import ResRiskProfile from './ResRiskProfile'
import InputGroup from 'react-bootstrap/InputGroup';
import { upperCase } from "lodash";
import CardUSSD from "../payment/CardUSSD";
import { referenceID } from "../resources/ResourceFunctions";
import { useForm } from "react-hook-form";

function Learn1(props) {
    const globalRefId = "";
    const [step, setStep] = useState(1)
    const [minimum, setMinimum] = useState(0)
    const [convertedAmount, setConvertedAmount] = useState(0)
    const [riskProfile, setRiskProfile] = useState(false);
    const [id, setId] = useState()
    const [fundManagerCountry, setFundManagerCountry] = useState('')
    const [fundManagerId, setFundManagerId] = useState(0)
    const [interest, setInterest] = useState()
    const [handler, setHandler] = useState()
    const [description, setDescription] = useState()
    const [optionName, setOptionName] = useState()
    const [classLogo, setClassLogo] = useState()
    const [className, setClassName] = useState()
    const [classId, setClassId] = useState()
    const [fundClass, setFundClasses] = useState()
    const [classOptions, setClassOptions] = useState()
    const [classDescription, setClassDescription] = useState()
    const [internalRef, setInternalRef] = useState('')
    const [formData, setFormData] = useState({
        "payment_means": 'online',
        "deposit_amount": 0,
        "investment_id": 0,
        "currency": getCurrency(props.country),
        "investment_option": '',
        "investment_class": "",
        "deposit_category": "personal",
        "account_type": "",
        "reference": "",
        "reference_id": 0,
        "tx_ref": "CYANASE-TEST-001",
        "phone_number": props.phone

    });
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({...formData, [name]: value });
    };
    function getOptionDetails(option){
        axios.post(API_URL_GET_INVESTMENT_OPTION, option, {headers: {
            "Authorization": `Token ${TOKEN}`,
            "Content-Type": "application/json"
        }}).then(function(res)
            {
                if(res){
                    setOptionName(res.data[0].investment_option)
                    setInterest(res.data[0].interest);
                    setHandler(res.data[0].handler);
                    setDescription(res.data[0].description);
                    _next()
                }
            }
        )
    }
    function getFundClass(id){
        axios.post(API_URL_GET_FUND_INVESTMENT_CLASS, id, {headers: {
            "Authorization": `Token ${TOKEN}`,
            "Content-Type": "application/json"
        }}).then(function(res)
            {
                if(res){
                    setFundClasses(res.data)
                    setFundManagerId(res.data[0].fund_id)
                    _next()
                }
            }
        )
    }
    function getClassOptions(option, description, id, logo){
        axios.post(API_URL_GET_INVESTMENT_CLASS_OPTIONS, option, {headers: {
            "Authorization": `Token ${TOKEN}`,
            "Content-Type": "application/json"
        }}).then(function(res)
            {
                if(res){
                    setClassOptions(res.data)
                    setClassName(option)
                    setClassDescription(description)
                    setClassLogo(logo)
                    setClassId(id)
                    _next()
                }
            }
        )
    }
    const getTotalDeposit = () => {
        let total_deposit = parseFloat(getFee()) + parseFloat(formData.deposit_amount)
        return total_deposit
    }
    const getFee = () => {
        let fee = ((1.4 / 100) * formData.deposit_amount).toFixed(2)
        return fee
    }
    const relCharge = () => {
        let fee = (2.5 / 100) * formData.deposit_amount
        fee = fee + parseFloat(getFee())
        return fee
    }
    const getTab9 = () => {
        return props.tab9
    }
    const getTotalDeposit2 = () => {
        let total_deposit = parseFloat(getFee()) + parseFloat(formData.deposit_amount) + parseFloat(relCharge())
        return total_deposit
    }

    function convert(){
        let option = formData.investment_option
            axios.post(API_URL_GET_INVESTMENT_OPTION, option, {headers: {
                "Authorization": `Token ${TOKEN}`,
                "Content-Type": "application/json"
            }}).then(function(res)
                {
                    if(res){
                        setMinimum(res.data[0].minimum_deposit);
                        setId(res.data[0].investment_option_id);
                        setFundManagerCountry(res.data[0].fund_manager_country);
                    }
                }
            )
    }
    if(formData.investment_option !== ""){
        convert()
    }
    const getAccountType = () => {
        let currency = formData.currency
        let accountType = formData.account_type
        if (currency === "USD") {
            accountType = "dollar"
        } else {
            accountType = "basic"
        }
        return accountType
    }
    formData.account_type = getAccountType()
    formData.currency = getCurrency(props.country)
    const { handleSubmit } = useForm();

    function onSubmit() {
        preloader()
    }
    function validate1(minimum, id, fundManagerCountry) {
        let depositAmount = ValidateForms("deposit_amount")
        let deposit_amount = formData.deposit_amount
        let currency = formData.currency
        formData.investment_id = id

        if (depositAmount.length === 0) {
            document.getElementById("errorFirst").style.display = "block"
            document.getElementById("errorFirst").style.color = "crimson"
            document.getElementById("errorFirst").innerText = "deposit amount is required"
        } else if (deposit_amount < parseInt(convertedAmount)) {
            document.getElementById("errorFirst").style.display = "block"
            document.getElementById("errorFirst").style.color = "crimson"
            document.getElementById("errorFirst").innerText = "minimum deposit required for this investment class is "+currency+" "+parseInt(convertedAmount).toLocaleString()
        }else{
            document.getElementById("errorFirst").style.display = "none"
            setStep(step + 1)
        }
    }
    const _next = () => {
        setStep(step + 1)
    }
    const chunkArray = (arr, n) =>{
        let chunkLength = Math.max(arr.length/n, 1)
        let chunks = [];
        for(let i =0;i<n;i++){
            if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
        }
        return chunks;
    }
    const _prev = () => {
        setStep(step - 1)
    }
    const _prev2 = () => {
        setStep(step - 2)
    }
    const previousButton = () => {
        if (step === 2) {
            return ( 
                <h6 className = " text-start warning rounded-3 my-2"
                type = "button"
                onClick = { _prev } >
                Previous </h6>
            )
        }
        if (step !== 1) {
            return ( 
                <h6 className = " text-start warning rounded-3 mt-2"
                type = "button"
                onClick = { _prev2 } >
                Previous </h6>
            )
        }
        if(step === 9){
            return( 
                <h6 className = " text-start warning rounded-3 mt-2"
                type = "button"
                onClick = { _prev2 } >
                Previous </h6>
            )
        }
        return null;
    }
    const submitButton = () => {
        let payment_means = formData.payment_means;
        let currency = formData.currency;

        if (step === 7 && payment_means === "online") {
            if(currency !== "UGX" || currency !== "KES" || currency !== "TZS")
            {
                return ( 
                <div className = ' text-center' > 
                <h6 id = "errorMessage"
                className = 'py-2 mt-3 mx-3 rounded border border-danger text-center'
                style = {
                    { display: 'none' }
                } > hey </h6> 
                <h6 id = "infoMessage"
                className = 'py-2 mt-3 mx-3 rounded warning-message text-center'
                style = {
                    { display: 'none' }
                } > hey </h6>   
                <h5 style = {
                    { display: 'none' }
                }
                onClick={()=> autoClickable()}
                className = 'rounded-3 bluey mx-5 p-2 text-center my-2'
                id = 'successMessage' >hey</h5> 
                </div>
            )} else {
            return null}
        }
        if (step === 9 && payment_means === "online") {
            if(currency === "UGX" || currency === "KES" || currency === "TZS")
            {
                return ( 
                <div className = ' text-center' > 
                <h6 id = "errorMessage"
                className = 'py-2 mt-3 mx-3 rounded border border-danger text-center'
                style = {
                    { display: 'none' }
                } > hey </h6> 
                <h6 id = "infoMessage"
                className = 'py-2 mt-3 mx-3 rounded warning-message text-center'
                style = {
                    { display: 'none' }
                } > hey </h6>   
                <h5 style = {
                    { display: 'block' }
                }
                className = 'btn btn-warning mx-5 p-2 text-center my-2'
                id = 'successMessage'
                onClick={Submit} >
                MAKE PAYMENT </h5> 
                </div>
            )}
            return null
        }
        return null
    }
    const Submit = () => {
        preloader()
        //send request to relworx - initiate
        let ref = referenceID()
        let formdata = new FormData();
        formdata.append("payment_means", formData.payment_means)
        formdata.append("account_type", formData.account_type)
        formdata.append("currency", formData.currency)
        formdata.append("deposit_amount", formData.deposit_amount)
        formdata.append("deposit_category", formData.deposit_category)
        formdata.append("investment_class", formData.investment_class)
        formdata.append("investment_id", formData.investment_id)
        formdata.append("investment_option", formData.investment_option)
        formdata.append("reference", ref)
        formdata.append("reference_id", 1856231518)
        let phone = {
            "msisdn": formData.phone_number,
        }
        let data = {
            "account_no": "REL6AEDF95B5A",
            "reference": ref,
            "msisdn": formData.phone_number,
            "currency": formData.currency,
            "amount": getTotalDeposit2(),
            "description": "Payment Request."
        }
        // validate phone number
        axios.post(`${API_URL_VALIDATE_MM_NUMBER}`, phone, {headers: {
            "Content-Type": "multipart/form-data",
            'Accept': 'application/json',
            "Authorization": `Token ${ TOKEN }`
        }})
        .catch(function(error) {
            catch_errors(error)
        })
        .then(function(response) {
            if (response.data.success !== true) {
                fail("Something went wrong...", "Error")
            } else {
                // send request for payment
                axios.post(`${API_URL_REQUEST_PAYMENT}`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Accept': 'application/json',
                        "Authorization": `Token ${ TOKEN }`
                }})
                .catch(function(error) {
                    catch_errors(error)
                })
                .then( function(response) {
                    success1("Proceed to approve your transaction", "WAITING TO COMPLETE TRANSACTION...")
                    if(response.data.message === "Request payment in progress." && response.data.success === true){
                        // compare internal reference and update the
                        success1("Processing Transaction...", "PROCESSING...")
                        setInternalRef(response.data.internal_reference)
                        setTimeout(() => {
                            let data = new FormData()
                            data.append("internal_reference", response.data.internal_reference)
                            axios.post(`${API_URL_GET_TRANSACTION}`, data, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                    'Accept': 'application/json',
                                    "Authorization": `Token ${ TOKEN }`
                                }
                            })
                            .catch(function(error){
                                catch_errors(error)
                            })
                            .then(function(response){
                                if(response !== undefined && response.data.success === true){
                                    // upload to server
                                    axios.post(`${API_URL_DEPOSIT}`, formdata, {
                                        headers: {
                                        "Content-Type": "multipart/form-data",
                                        'Accept': 'application/json',
                                        "Authorization": `Token ${ TOKEN }`
                                    }})
                                    .catch(function(error) {
                                        catch_errors(error)
                                    })
                                    .then(function(response) {
                                        if(response.data.success === true){
                                            success("You have deposited successfully", "/home", "successful");
                                        }
                                    })
                                }else {
                                    fail("Transaction Timeout. Please try again", "unsuccessful")
                                }
                            })
                        }, 30000)
                    }
                })
            }
        });
    }
    const nextButton = () => {
        let payment_means = formData.payment_means;
        let deposit_category = formData.deposit_category
        let verification = props.verification
        let investmentOption = formData.investment_option
        let riskProfileStatus = props.complete
        if (step === 1 && deposit_category === "personal" && verification === true) {
            return null
        }
        if (step === 1 && verification === false) {
            // simple - only verified users can interact with this feature
            return ( 
                <h6 className = "m-3 p-2 status rounded-3">
                Please check your email and verify your account to proceed 
                </h6>        
            )
        }
        if (step === 2 && investmentOption !== "Automatic"){
            return null
        }
        if (step === 2 && investmentOption === "Automatic"){
            // verify risk profile option was selected or not and check risk profile status
            if (riskProfileStatus === "Complete"){
                return null
            } else {
                return (
                    <h6 className = " m-3 p-2 status rounded-3" >
                Please complete your risk profile to have your assets allocated automatically. </h6> 
                )
            }
        }if (step === 3){
            return null
        }
        if (step === 4) {
            function setOptionFormData (){
                formData.investment_option = optionName
                formData.investment_class = className
                formData.investment_id = classId
                _next()
            }
            return ( 
                <h5 className = " my-2 mb-4 btn btn-warning py-2 px-3"
                onClick = { () => setOptionFormData() } >
                Invest with this option 
                </h5>        
            )
        }
        if (step === 5 && payment_means === "offline") {
            return ( 
                <h5 className = " my-2 text-end bk-warning rounded-3 py-2 px-3"
                type = "button"
                onClick = { _next } >
                Continue 
                </h5>        
            )
        }
        if (step === 6) {
            return ( 
                <h6 className = " my-2 text-end warning rounded-3"
                onClick = { () => validate1(minimum, id, fundManagerCountry) } >
                Next 
                </h6>        
            )
        }
        if (step === 7 && payment_means === "offline") {
            return ( 
                <h6 className = " my-2 btn btn-warning p-2"
                onClick = { _next } >
                Continue to Deposit Offline 
                </h6>        
            )
        }
        if (step === 7 && payment_means === "online") {
            return null
        }
        if (step < 8) {
            return ( 
                <h6 className = " my-2 text-end warning rounded-3"
                onClick = { _next } >
                Next 
                </h6>        
            )
        }
        return null
    }
    if (riskProfile === true) {
        return ( 
        <ResRiskProfile changeRiskProfileSetting = { setRiskProfile }/>
        )
    }

    return ( 
        <React.Fragment >
        <form className = "text-center pb-5"
        onSubmit = { handleSubmit(onSubmit) } > {
            /* 
                      render the form steps and pass required props in
                    */
        } <div className="d-none d-md-block d-lg-block px-3 rounded-top-3"><h3 className=" py-4">Deposit</h3></div>
        <Step1 currentStep = { step }
        deposit_category = { formData.deposit_category }
        handleChange = { handleChange }
        getTab9 = { getTab9() }
        investmentClass = { props.option }
        options = {props.options}
        investment_class = { formData.investment_class }
        investment_option = {formData.investment_option}
        chunks = {chunkArray}
        setRiskProfile = {setRiskProfile}
        fundClass = {getFundClass}
        fundManagers = {props.setFundManagers}
        /> 
        <Step2 currentStep = { step }
        classes = {fundClass}
        getTab9 = { getTab9() }
        className = {className}
        setRiskProfile = {setRiskProfile}
        classDescription = {classDescription}
        getClass = {getClassOptions}
        classLogo = {classLogo}
        fundClass = {fundManagerId}
        getOptionDetails = {getOptionDetails}
        />
        <Step3 currentStep = { step }
        className = {className}
        classDescription = {classDescription}
        classLogo = {classLogo}
        getOptionDetails = {getOptionDetails}
        classOptions = {classOptions}
        />  <Step4  currentStep = { step }
        setStep = {setStep}
        interest = { interest }
        description = { description }
        handler = { handler }
        handleChange = { handleChange }
        optionName = {optionName}
        /> 
        <Step5 currentStep = { step }
        handleChange = { handleChange }
        />
        <Step6 currentStep = { step }
        handleChange = { handleChange }
        currency = { formData.currency }
        payment_means = { formData.payment_means }
        minimum = { minimum }
        fundManagerCountry = { fundManagerCountry }
        setConvertedAmount = { setConvertedAmount }
        /> 
        <Step7 currentStep = { step }
        setStep = {setStep}
        handleChange = { handleChange }
        phone = { props.phone }
        email = { props.email }
        name = { props.lastname }
        country = { props.country }
        globalRefId = { globalRefId }
        payment_means = { formData.payment_means }
        deposit_amount = { formData.deposit_amount }
        total_deposit = { getTotalDeposit() }
        total_deposit2 = { getTotalDeposit2() }
        rel_deposit = {relCharge()}
        fee = {
            getFee()
        }
        data = { formData }
        getCurr = { getCurrency(props.country) }
        currency = { formData.currency }
        riskAnalysisPercentages = {props.riskAnalysisPercentages}
        /> 
        <Step8 currentStep = { step }
        handleChange = { handleChange }
        payment_means = { formData.payment_means }
        total_deposit = { getTotalDeposit() }
        currency = { formData.currency }
        getCurr = { getCurrency(props.country) }
        /> 
        <Step9 currentStep = { step }
        handleChange = { handleChange }
        payment_means = { formData.payment_means }
        total_deposit = { getTotalDeposit() }
        phone = { props.phone }
        submit = { handleSubmit }
        currency = { formData.currency }
        getCurr = { getCurrency(props.country) }
        /> { previousButton() }{ nextButton() }{submitButton()}
        </form> 
        </React.Fragment>
    );
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    function profileImage(image, fname){
        if(image.search("photo.png") > 0){
            return (
                <h3 className='avatars rounded-circle p-2 text-center'>{upperCase(fname[0])}</h3>
            )
        } else {
            return (
                <img src={image} width={50} height={50} alt="logo" className=" rounded-circle"/>
            )
        }
    }
    return ( 
        <div className = "bg-white px-lg-3 text-dark" > 
        <h6 className = "my-3 d-none d-md-block d-lg-block" > Select a Fund Manager </h6> 
        <h5 className = "text-start m-3 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Select a Fund Manager </h5> 
        <h6 className = "btn btn-warning p-2 my-3 text-center d-none d-lg-block"
        onClick = { props.getTab9 } >
        Edit my Risk Profile before deposit </h6>
        <h5 className = "btn btn-warning p-2 mb-3 text-center d-lg-none d-md-none d-sm-block"
        onClick = { () => {props.setRiskProfile(true)} } >
        Edit my Risk Profile before deposit </h5>
        <div className="scroll-y5 px-3">
        <div className="">
        {
            props.fundManagers?.map((fund, id) => {
                return <div key={id} onClick={() => props.fundClass(fund.user_id)}><div className="row tab-nav border mx-1 rounded-4 mb-2">
                <div className="col-3 text-center p-2">{profileImage(fund.profile.profile_picture, fund.first_name)}</div>
                <div className="col-9 bluey p-3 text-start">
                <div><h5 className="bolder">{fund.first_name} {fund.last_name}
                <h5 className="lh-1">{fund.profile.company_category}</h5></h5></div></div></div>
                </div>
            })
        }
        </div>
        </div>
        </div>
    );
}
function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }
    if(props.classes.length !== 0){
    return ( 
        <div className = "bg-white px-3 text-dark" > 
        <h5 className = "text-start d-lg-none d-md-none d-sm-block mt-5 pt-4" > Select an Investment Class </h5>
        <h6 className = "my-3 d-none d-md-block d-lg-block" > Select an Investment Class </h6> 
        <h5 className = "text-start my-3 d-none mt-5 pt-4" > Invest in {props.className}? </h5> 
        <div className="bg-light row m-1 p-2 rounded-3 d-none">
            <div className="col-2"><img src={props.classLogo} width={30} height={40} alt="logo"/></div>
            <div className="col-10 text-start">
                <h5 className="m-2"><span className="bolder pt-2">{props.className}</span><br/>{props.classDescription}</h5>
            </div>
        </div>
        <h6 className = "btn btn-warning p-2 my-3 text-center d-none d-lg-block"
        onClick = { props.getTab9 } >
        Edit my Risk Profile before deposit </h6>
        <h5 className = "btn btn-warning p-2 mb-3 text-center d-lg-none d-md-none d-sm-block"
        onClick = { () => {props.setRiskProfile(true)} } >
        Edit my Risk Profile before deposit </h5>
        <div className="scroll-y3 px-3">
        <div className="">
        {
            props.classes?.map((options, id) => {
                let data = new FormData()
                data.append("class", options.name)
                data.append("fund_id", props.fundClass)
                return <div key={id} onClick={()=> props.getClass(data, options.description,options.id,options.logo)}><div className="row border mx-1 rounded-3 mb-2 tab-nav">
                <div className="col-2 text-center p-2"><img src={options.logo} width={30} height={40} alt="logo" className="mt-2"/></div>
                <div className="col-10 bluey p-3 text-start">
                <div><h5 className="bolder">{options.name}
                <h5 className="lh-1">{options.description}</h5></h5></div></div></div>
                </div>
            })
        }
        </div>
        </div>
        </div>
    )}
    else {
        return(
            <div className = "bg-white px-3 text-dark text-start" > 
            <h6 className = " my-3 d-none d-md-block d-lg-block" > Select an Investment Option </h6> 
            <h6 className = "text-start my-3 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Select an Investment Option </h6> 
        <div className="bg-light row m-2 px-3 rounded-3">
            <div className="col-2 my-3"><img src={props.classLogo} width={30} height={40} alt="logo"/></div>
            <div className="col-10 mt-3 text-start">
                <h6><span className="bolder">{props.className}</span><p className="small">{props.classDescription}</p></h6>
            </div>
        </div>
        <div className="mt-2 py-3 text-start row">
        <div className="col-2"><InfoCircle/></div>
        <div className="col-10">
            <h6 className="bolder">Unfortunately we dont have any investment options under {props.className} at the moment. Please try our other classes for more options. <br/><br/> Thank you</h6></div>
        </div>
        </div>
        )
    }
}
function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }
    return ( 
        <div className = "bg-white px-3 text-dark" > 
        <h6 className = " my-3 d-none d-md-block d-lg-block" > Select an Investment Option </h6> 
        <h5 className = "text-start my-3 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Select an Investment Option under {props.className} </h5>
        {
            props.classOptions.map((options, id) => {
                return <div key={id} className="row m-2 border rounded-3"><div className="col-10 p-3 text-start"><h5>{options.investment_option}</h5></div><div className="col-2 black-hover rounded-end-3 p-3" onClick={()=> props.getOptionDetails(options.investment_option) }><ChevronRight set="broken"/></div></div>
                        
            })
        } 
        <div className="bg-light d-none row p-2 mx-1 rounded-3">
            <div className="col-2"><img src={props.classLogo} width={30} height={40} alt="logo"/></div>
            <div className="col-10 text-start">
                <h5 className="m-2"><span className="bolder">{props.className}</span><br/>{props.classDescription}</h5>
            </div>
        </div>
        <div className="row py-3 d-none mx-1 mt-2"><div className="col-10 text-start"><h5>{props.optionName}</h5></div><div className="col-2"><ChevronUp onClick={()=> props.setStep(2)}/></div></div>
        <div className="border-top row d-none py-2 mx-2 text-start bluey">
            <h6 className="bolder mt-2">Description:
            <h5 className="lh-1">{props.description}</h5></h6>
            <h6 className="bolder">Handler:
            <h5 className="">{props.handler}</h5></h6>
            <h6 className="bolder">Annual Return:
            <h5 className="">{props.interest}%</h5></h6>
        </div>
        </div>
    );
}
function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return ( 
        <div className = "bg-white px-3 text-dark" > 
        <h6 className = " my-3 d-none d-md-block d-lg-block" > Select an Investment Option </h6> 
        <h5 className = "text-start my-3 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Invest in {props.optionName}? </h5>
        <div className="row py-3 mx-1 mt-2"><div className="col-10 text-start"><h5>{props.optionName}</h5></div><div className="col-2 tab-nav"><ChevronUp onClick={()=> props.setStep(3)}/></div></div>
        <div className="border-top row py-2 mx-2 text-start bluey">
            <h6 className="bolder mt-2">Description:
            <h5 className="lh-1">{props.description}</h5></h6>
            <h6 className="bolder">Handler:
            <h5 className="">{props.handler}</h5></h6>
            <h6 className="bolder">Annual Return:
            <h5 className="">{props.interest}%</h5></h6>
        </div>
        </div>
    );
}
function Step5(props) {
    if (props.currentStep !== 5) {
        return null
    }
    return ( 
        <div className = " text-start" > 
        <h6 className = "text-center my-3 d-none d-md-block d-lg-block" > Choose your payment means </h6> 
        <h5 className = "text-center my-2 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Choose your payment means </h5>  
        <div className = "p-4 rounded-4"
        key = "radio" >
        <div key = { `radio` }
        className = "mb-3" >
        <h5 className = "font-lighter d-none" > WALLET </h5> 
        <Form.Check label = "I want to deposit from my wallet to make this deposit to my personal investment account"
        name = "payment_means"
        type = "radio"
        onChange = { props.handleChange }
        value = "wallet"
        className = "d-none"
        required id = "radio1" />
        <h5 className = "font-lighter" > OFFLINE </h5> 
        <Form.Check label = "Deposit directly to our bank account and let us reconcile your account"
        name = "payment_means"
        onChange = { props.handleChange }
        type = "radio"
        className="tab-nav"
        value = "offline"
        required id = "radio2" />
        <h5 className = "font-lighter mt-5" > ONLINE </h5> 
        <Form.Check label = "Make an instant deposit on our platform"
        name = "payment_means"
        onChange = { props.handleChange }
        type = "radio"
        className="tab-nav"
        value = "online"
        required id = "radio3"/>
        </div> </div> 
        </div>
    );
}

function Step6(props) {
    let min = props.minimum
    let fund = getCurrency((props.fundManagerCountry).toUpperCase()).toLowerCase()
    let curr = (props.currency).toLowerCase()
    if (props.currentStep !== 6) {
        return null
    }
    props.setConvertedAmount(Conversion(fund,min,curr))
    return ( 
        <div className = "text-center" >  
            <Form.Group className = "mb-3 bg-white mt-5 p-4" >
            <Form.Label ><h5 className = 'mt-3' > How much would you like to deposit? </h5></Form.Label>
            <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{props.currency}</InputGroup.Text>
            <Form.Control type = "number"
            onChange = { props.handleChange }
            name = "deposit_amount"
            id = 'phone'
            size="lg"
            required placeholder = "0.00" />
            <div className="w-100 mt-2">
            <p id = "errorFirst"
            className = 'p-2 rounded-2 px-3 bg-red'
            style = {
                { display: 'none' }
            } > hey </p>
            <p id = "convertedAmount"
            className = 'p-2 rounded-2 px-3 bg-red'
            style = {
                { display: 'none' }
            } > {Conversion(fund,min,curr)} </p></div>
            <Form.Control.Feedback type = "invalid" >
            This field is required
            </Form.Control.Feedback> 
            </InputGroup>
            </Form.Group>
        </div>
    );
}

function Step7(props) {
    if (props.currentStep !== 7) {
        return null
    }
    if (props.payment_means === "offline") {
        return ( 
            <div className = "text-center" > 
            <h6 className = "text-center my-3 d-none d-md-block d-lg-block" > Deposit Offline </h6> 
            <h6 className = "text-center my-2 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Deposit Offline </h6>   
            <h5 className = "p-5" > Proceed to deposit < span className = "bolder" > { props.currency } </span> 
            <span className = "bolder" > { props.deposit_amount } </span> plus a flat fee of <span className = "bolder" > { props.currency } </span> 
            <span className = "bolder">{props.fee} </span> .Your Total deposit amount is <span className = "bolder" > { props.currency } </span> 
            <span className = "bolder active" > { props.total_deposit} </span> 
            </h5> 
            </div>
        )
    }
    if (props.payment_means === "online") {
        // relworx + flutterwave for eastafrica
        if(props.currency === "UGX" || props.currency === "KES" || props.currency === "TZS")
        {
            return ( 
            <div className = "text-center" >
            <h6 className = "text-center my-3 d-none d-md-block d-lg-block" > Deposit Online </h6> 
            <h5 className = "text-center my-2 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Deposit Online </h5> 
            <h5 className = "p-5" > Proceed to deposit < span className = "bolder bluey" > { props.currency } </span> 
            <span className = "bolder bluey" > { props.deposit_amount } </span> plus a flat fee of 
            <span className = "bolder bluey" > { props.currency } </span> <span className = "bolder bluey">{props.fee} </span> .Your Total deposit amount is 
            <span> { props.currency } </span> 
            <span className = "bolder bluey" > { props.total_deposit} </span> </h5>
                <div className="mb-3 p-3 bg-light">
                <h5 className="p-3 rounded-3 account border mx-5" onClick={() => props.setStep(9)}>
                    Mobile Money
                </h5>
            <CardUSSD phone = { props.phone } // here the checkout form is rendered after which it returns response
            country = { props.country }
            name = { props.name }
            email = { props.email }
            amount = { props.total_deposit }
            currency = { props.getCurr }
            data = { props.data }
            submit = { props.submit }
            riskAnalysisPercentages = {props.riskAnalysisPercentages}
            />  </div>
            </div>
        );}
        else {
            // flutterwave
            return ( 
                <div className = "text-center" >
                <h6 className = "text-center my-3 d-none d-md-block d-lg-block" > Deposit Online </h6> 
                <h5 className = "text-center my-2 d-lg-none d-md-none d-sm-block mt-5 pt-4" > Deposit Online </h5> 
                <h5 className = "p-5" > Proceed to deposit < span className = "bolder bluey" > { props.currency } </span> 
                <span className = "bolder bluey" > { props.deposit_amount } </span> plus a flat fee of 
                <span className = "bolder bluey" > { props.currency } </span> <span className = "bolder bluey">{props.fee} </span> .Your Total deposit amount is 
                <span> { props.currency } </span> 
                <span className = "bolder bluey" > { props.total_deposit} </span> </h5>
                <Checkout phone = { props.phone } // here the checkout form is rendered after which it returns response
                country = { props.country }
                name = { props.name }
                email = { props.email }
                amount = { props.total_deposit }
                currency = { props.getCurr }
                data = { props.data }
                submit = { props.submit }
                riskAnalysisPercentages = {props.riskAnalysisPercentages}
                />
                </div>
            );
        }
    }
}

function Step8(props) {
    if (props.currentStep !== 8) {
        return null
    } else if (props.payment_means === "offline") {
        return ( 
            <div className = "text-center" > 
            <h4 className = "bolder my-3" > Make an Offline Deposit </h4> 
            <h6 className = "mt-2" > Procedure </h6>   
            <h5 className = "p-4" > Deposit 
            <span className = "bolder" > { props.currency } </span>: <span className="bolder">{ props.total_deposit} </span>
            to our bank account and proceed to send us your deposit receipt </h5>
            <div className = "row p-4" >
            <div className = "col-5 text-start" >
            <h5 className = "bolder border-bottom" > Bank name </h5> 
            <h5 className = "bolder border-bottom" > Account number </h5> 
            <h5 className = "bolder border-bottom" > SWIFT CODE </h5>  
            <h5 className = "bolder border-bottom" > Account name </h5>
            </div> 
            <div className = "col-7 text-start" >
            <h5 className = "font-lighter border-bottom" > DIAMOND TRUST BANK </h5>  
            <h5 className = "font-lighter border-bottom" > 0190514001 </h5> 
            <h5 className = "font-lighter border-bottom" > DTKEUGKAXXX </h5>
            <h5 className = "font-lighter border-bottom" > CYANASE TECHNOLOGY AND INVESTMENT LTD </h5> </div> 
            </div>  
            <h6 className = "p-4 status my-3" > Send your deposit receipt to our Email: 
            <span className = "bolder active" > <u> 'deposit@cyanase.com' </u> </span > </h6>  
            </div>
        )
    }
        return ( 
            <div className = "text-center" >offline deposit
            </div>
        );
    }

function Step9(props){
    if(props.currentStep === 9){
        return(
            <div>
                <div className = "text-center" > 
                <h6 className = "mt-2 d-none d-md-block d-lg-block" > Pay with Mobile Money </h6>
                <h4 className="mt-5 pt-5 d-sm-block d-lg-none">Pay with Mobile Money</h4>
                <Form.Group className = " p-3" >
                <Form.Label > <h6 > Enter Phone Number </h6> </Form.Label> 
                <Form.Control type = "phone"
                onChange = { props.handleChange }
                name = "phone_number"
                id = 'phone_number'
                required placeholder = { props.phone }
                />
                <p id = "errorPhone"
                className = 'p-2 rounded-2 px-3 bg-red'
                style = {
                    { display: 'none' }
                } > hey </p>
                <p className="p-2 bg-lighter rounded-2 mt-2">Approval should happen within 60 seconds otherwise the transaction window will expire</p>
                <Form.Control.Feedback type = "invalid" >
                This field is required. 
                </Form.Control.Feedback> </Form.Group>
                </div>
            </div>
        )
    }
    return null
}

export default Learn1;