export function referenceID() {
    var rand = function() {
        return Math.random().toString(36).substr(2); // remove `0.`
    };
    
    var ref = function() {
        return rand() + rand() + rand(); // to make it longer
    };

    return ref()
}